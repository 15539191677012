import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { CommonService } from '../service/common.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PreMobilzationService {

  constructor(public secure: StorageService,private http: HttpClient, private commonService : CommonService ) { }

  savePreMobilizationsForCustomer(data: {
    customerId : String,
    premobilizationTimelineFlag : String,
    opprtunityNo : String,
    premobiliztions_customer_attachement: String,
    created_by : String,
  }){
    return this.http.post(environment.url + 'preMobilization/savePreMobilizationsForCustomer', data,{ headers: { 'x-access-token': this.commonService.token } });
  }
  getCountDownTimerDaysForCustomer() {
    return this.http.get(environment.url + 'preMobilization/getCountDownTimerDaysForCustomer',{ headers: { 'x-access-token': this.commonService.token } });
  }
    getCountDownTimerDays() {
    return this.http.get(environment.url + 'preMobilization/getCountDownTimerDays',{ headers: { 'x-access-token': this.commonService.token } });
  }
  getAllDisMentalClearanceInspectionTypesForCustomer() {
    return this.http.get(environment.url + 'preMobilization/getAllDisMentalClearanceInspectionTypesForCustomer',{ headers: { 'x-access-token': this.commonService.token } });
  }
  getAllInProcessInspectionTypesForCustomer(customerId,page) {
    return this.http.get(environment.url + 'preMobilization/getAllInProcessInspectionTypesForCustomer/'+customerId+'/'+page,{ headers: { 'x-access-token': this.commonService.token } });
  }
  getAllCompletedInspectionTypesForCustomer(customerId) {
    return this.http.get(environment.url + 'preMobilization/getAllCompletedInspectionTypesForCustomer/'+customerId,{ headers: { 'x-access-token': this.commonService.token } });
  }

  savePreMobilizationsForm(savePreMobilizationsFormData: {
    ReceiptofCrane : String,
    Span : String,             
    CraneBeamLevel : String,
    CraneBeamDiagonalCheck: String,
    Name : String,
    Designation : String,
    createdBy: any,
    PhoneNo : any,
    EMail : any,  
    DetailsofNearest : String,
    ProvisionofScaffolding : String,
    AreRoadPermits : String,
    Aretestloads : String,
    IsStorageArea : String,
    ClearAccess : String,
    AsmentionedintheGA : String,
    opprtunity_no: any,
    client_id: any,
    
  }){
    //console.log("savePreMobilizationsFormData",savePreMobilizationsFormData)
    return this.http.post(environment.url + 'preMobilization/savePreMobilizationsForm',savePreMobilizationsFormData,{ headers: { 'x-access-token': this.commonService.token } });
  }
   
  getPreMobilizationVew(
    opprtunity_no : any
   ){
     //console.log("hiii",opprtunity_no);
      return this.http.get(environment.url + 'preMobilization/getPreMobilizationView/'+opprtunity_no);
    } 
  
}
//savePreMobilizationsForCustomer, getCountDownTimerDaysForCustomer, getAllDisMentalClearanceInspectionTypesForCustomer, getAllInProcessInspectionTypesForCustomer, getAllCompletedInspectionTypesForCustomer