import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';

import { StorageService } from './storage.service';
@Injectable({
  providedIn: 'root'
})
export class PerformaInvoiceService {
  public zohoId = '';

  constructor(public secure: StorageService,private http: HttpClient) { }

  getCountDownTimerDaysforProformaInvoice() {
    return this.http.get(environment.url + 'performaInvoice/getCountDownTimerDaysforProformaInvoiceCust');
  }
  getPaymentListFormCustomer(customerId) {
    return this.http.get(environment.url + 'performaInvoice/getPaymentListFormCustomer/' + customerId);
  }
  getPaymentCompletedListFormCustomer(customerId) {
    return this.http.get(environment.url + 'performaInvoice/getPaymentCompletedListFormCustomer/' + customerId);
  }
  getPaymentWaitingForCustomerProformaListFormCustomer(customerId,page) {
    return this.http.get(environment.url + 'performaInvoice/getPaymentWaitingForCustomerProformaListFormCustomer/' + customerId+'/'+page);
  }
  getPaymentPBGDate(OpportunityNo) {
    return this.http.get(environment.url + 'performaInvoice/getPaymentPBGDateCust/' + OpportunityNo);
  }
  getPaymentABGDate(OpportunityNo) {
    return this.http.get(environment.url + 'performaInvoice/getPaymentABGDateCust/' + OpportunityNo);
  }
  getPaymentLCDate(OpportunityNo) {
    return this.http.get(environment.url + 'performaInvoice/getPaymentLCDateCust/' + OpportunityNo);
  }

  savePorformaInvoiceReciptFromCustomer(data: {
    OpportunityNo: String,
    CrID: String,
    crVersionId: String,
    paymentTermId: String,
    crVersionPaymentTermId: String,
    porformaInvoiceReciptAttachement: String,
    created_by: String,
    porformaInvoiceId: String,
    ProformaInvoiceTimeLineFlag: String,
    paymentTerm: String,
    pi_comment: String,
    pi_recepient_no: String,
  }) {

    return this.http.post(environment.url + 'performaInvoice/savePorformaInvoiceReciptFromCustomer', data);
  }
  getGadFlagStatusWRTOpportunityNoFromCustomer(OpportunityNo) {
    return this.http.get(environment.url + 'performaInvoice/getGadFlagStatusWRTOpportunityNoFromCustomer/' + OpportunityNo);
  }

}
