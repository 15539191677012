export const environment = {
  production: true,
 url:'https://apps.emech.in/api/',
//  url: 'http://192.168.3.160:3000/',
 // url: 'http://103.134.110.148:3000/',
 //url:'http://192.168.0.176:3005/'
 FZERegionZohoUrl: "https://crm.zoho.com/crm/org29780516/tab/Potentials/",
 RegionZohoUrlCheck: "7",
 OtherRegionZohoUrl: "https://crm.zoho.com/crm/org8604645/tab/Potentials/",
};
