import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';

import { CommonService } from '../service/common.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionQapService {
  getAllCustomerInspectionCompletedList(RoleID: string, arg1: string, arg2: string, arg3: string, selectedRegion: any) {
    throw new Error("Method not implemented.");
  }
  getCustomerInpectionOpen(productId: any, RoleID: string) {
    throw new Error("Method not implemented.");
  }
 
  constructor(public secure: StorageService,private http: HttpClient, private CommonService : CommonService) { }

  getCountDownTimerDays() {
    return this.http.get(environment.url + 'inspectionQap/getCountDownTimerDaysForCustomer' ,{ headers: { 'x-access-token': this.CommonService.token } });
  }
  saveQapfromcustomer(data: {
    qapComment: String,
    qapStatus : String,
    opportunity_no: String,
    qap_attachment: String,
    created_by: String,
    qap_timeline_flag: String,   
   
  }) {
    return this.http.post(environment.url + 'inspectionQap/saveQapfromcustomer', data ,{ headers: { 'x-access-token': this.CommonService.token } });
  }

  getQapWatingCustomerList(customer_id,page) {
    return this.http.get(environment.url + 'inspectionQap/getQapWatingCustomerList/'+customer_id+'/'+page ,{ headers: { 'x-access-token': this.CommonService.token } });
  }
 
  getQapApprovedList(customer_id) {
    return this.http.get(environment.url + 'inspectionQap/getQapApprovedList/'+customer_id ,{ headers: { 'x-access-token': this.CommonService.token } });
  }
  
  getHistoryUsingProductID(zohoid){
    return this.http.get(environment.url + 'inspectionQap/getHistoryUsingProductID/'+zohoid ,{ headers: { 'x-access-token': this.CommonService.token } });
    
  }
  getQapInprocessCustomerList(customer_id) {
    return this.http.get(environment.url + 'inspectionQap/getQapInprocessCustomerList/'+customer_id  ,{ headers: { 'x-access-token': this.CommonService.token } });
  }
  

// start customer inspection
getCustomerInprocessCustomerList(customer_id,page) {
  //console.log("customer inspection customer_id ",customer_id);
  return this.http.get(environment.url + 'inspectionQap/getCustomerInprocessCustomerList/'+customer_id+'/'+page  ,{ headers: { 'x-access-token': this.CommonService.token } });
}
// start customer inspection 
}
