import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';

import { CommonService } from '../service/common.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class GADService {
  public zohoId = '';

  constructor(public secure: StorageService,private CommonService: CommonService,private http: HttpClient) { }
  sendGADMail(data: {
    productnames: any, product_ids: any, created_by: any, opprtunity_no: any,
    page: any, roleType: any
  }) {

    return this.http.post(environment.url + 'gad/sendGADMailCust', data, { headers: { 'x-access-token': this.CommonService.token } });

  }


  getPointHistory(gadPointId) {
    return this.http.get(environment.url + 'gad/getPointHistoryCust/' + gadPointId, { headers: { 'x-access-token': this.CommonService.token } });

  }

  getAllGADStatus() {
    return this.http.get(environment.url + 'gad/getAllGADStatusCust', { headers: { 'x-access-token': this.CommonService.token } });
  }
  updateCustomerApproval(data: { productId: any, status: String, created_by: String }) {
    return this.http.post(environment.url + 'gad/updateCustomerApproval',data, { headers: { 'x-access-token': this.CommonService.token } });

  }
  getAttachmentWrtGADId(gadId, opprtunityNo) {
    return this.http.get(environment.url + 'gad/getAttachmentWrtGADIdCust/' + gadId + "/" + opprtunityNo, { headers: { 'x-access-token': this.CommonService.token } });
  }
  getRemarksGADIdAndVersion(gadId, opprtunityNo) {
    return this.http.get(environment.url + 'gad/getRemarksGADIdAndVersionCust/' + gadId + "/" + opprtunityNo, { headers: { 'x-access-token': this.CommonService.token } });
  }
  getNotesWrtGADId(gadId, opprtunityNo) {
    return this.http.get(environment.url + 'gad/getNotesWrtGADIdCust/' + gadId + "/" + opprtunityNo, { headers: { 'x-access-token': this.CommonService.token } });
  }
  getProductLinesWrtGADId(gadId, opprtunityNo, status) {
    return this.http.get(environment.url + 'gad/getProductLinesWrtGADIdCust/' + gadId + "/" + opprtunityNo + "/" + status, { headers: { 'x-access-token': this.CommonService.token } });
  }
  getAllGadList(custId,page,product_id) {
    return this.http.get(environment.url + 'gad/getAllGadList/' + custId+'/'+page+'/'+product_id, { headers: { 'x-access-token': this.CommonService.token } });
  }

  getCountDownTimerDaysafterSo() {
    return this.http.get(environment.url + 'gad/getCountDownTimerDaysafterSoCust', { headers: { 'x-access-token': this.CommonService.token } });
  }
  getPartiulrGadHistory(gadId) {
    return this.http.get(environment.url + 'gad/getPartiulrGadHistoryCust/' + gadId, { headers: { 'x-access-token': this.CommonService.token } });
  }

  getApproveForCustomerList(customerId) {
    //console.log(customerId)
    return this.http.get(environment.url + 'gad/getApproveForCustomerList/' + customerId ,{ headers: { 'x-access-token': this.CommonService.token } });
  }

  getTosterPendingCountFrCustomer(custId) {
    return this.http.get(environment.url + 'gad/getTosterPendingCountFrCustomer/' + custId, { headers: { 'x-access-token': this.CommonService.token } });
  }
  getTosterApproveCountFrCustomer(custId) {
    return this.http.get(environment.url + 'gad/getTosterApproveCountFrCustomer/' + custId, { headers: { 'x-access-token': this.CommonService.token } });
  }
  getTosterRejectCountFrCustomer(custId) {
    return this.http.get(environment.url + 'gad/getTosterRejectCountFrCustomer/' + custId, { headers: { 'x-access-token': this.CommonService.token } });
  }
  getTosterInWorkCountFrCustomer(custId) {
    return this.http.get(environment.url + 'gad/getTosterInWorkCountFrCustomer/' + custId, { headers: { 'x-access-token': this.CommonService.token } });
  }

  saveCustomerFilesForGAd(data: {
    Attachments: any
  }) {
    return this.http.post(environment.url + 'gad/saveCustomerFilesForGAd', data, { headers: { 'x-access-token': this.CommonService.token } });

  }
  GADRemarkNReplayCust(data: { gad_id: String, gad_version_id: String, remark: String, created_by: String }) {
    return this.http.post(environment.url + 'gad/GADRemarkNReplayCust', data, { headers: { 'x-access-token': this.CommonService.token } });

  }
  addPointReply(data: {
    pointData: any,
    gad_id:any,
    gad_version_id:any,
    customer_id:any
    

  }) {
    //console.log(data,"**********");
    return this.http.post(environment.url + 'gad/addGadPointReplyByCust', data, { headers: { 'x-access-token': this.CommonService.token } });

  }
  saveCustomerGad(data: {
    gadComment: String,
    gadStatus: String,
    productName: String,
    productCreatedBy: String,
    gadId: String,
    productId: String;
    gad_design_attachement: String,
    created_by: String, gadTimeLineApprovalFlag: String, gad_version_id: String
  }) {
   
    return this.http.post(environment.url + 'gad/saveCustomerGad', data, { headers: { 'x-access-token': this.CommonService.token } });
  }
  saveGad(data: {
    quotationId: String,
    productId: String,
    opprtunityNo: String,
    gad_design_attachement: String
    created_by: String
  }) {
    return this.http.post(environment.url + 'gad/saveGad', data, { headers: { 'x-access-token': this.CommonService.token } });
  }


}
