import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs';

import { CommonService } from '../service/common.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class NPSService {
  constructor(public secure: StorageService,private http: HttpClient, private CommonService : CommonService) { }


  addCustomerReview(data: {
    npscount: any,
    opprtunity_no : String,
    client_id : String,
    nps_rating : String,
    nps_suggestion: String,
    createdBy: String,

  
  }) {
    
    return this.http.post(environment.url + 'nps/addcustomerfeedback', data,{ headers: { 'x-access-token': this.CommonService.token } })
  }

  addSecondNPScustomerfeedback(data: {
    npscount: any,
    opprtunity_no : String,
    product_id:String,
    client_id : String,
    nps_rating : String,
    nps_suggestion: String,
    createdBy: String,

  
  }) {
    
    return this.http.post(environment.url + 'nps/addSecondNPScustomerfeedback', data,{ headers: { 'x-access-token': this.CommonService.token } })
  }

  getAllNpsOpenList(userId) {
    return this.http.get(environment.url + 'nps/getAllNpsOpenList/'+userId,{ headers: { 'x-access-token': this.CommonService.token } });
  }
  getAllSecondNpsOpenListForCus(userId) {
    return this.http.get(environment.url + 'nps/getAllSecondNpsOpenListForCus/'+userId,{ headers: { 'x-access-token': this.CommonService.token } });
  }


  
  getAllNpsViewData(zohoid){
    return this.http.get(environment.url + 'nps/getAllNpsViewDataForCustomer/'+zohoid,{ headers: { 'x-access-token': this.CommonService.token } });
  }

  getAllSecondNpsViewData(product_id){
    return this.http.get(environment.url + 'nps/getAllSecondNpsViewDataForCustomer/'+product_id,{ headers: { 'x-access-token': this.CommonService.token } });
  }
  getAllNpsCustomerReview(userId) {
    return this.http.get(environment.url + 'nps/getAllNpsCustomerReviewForCustomer/'+userId,{ headers: { 'x-access-token': this.CommonService.token } });
  }

  gettablecount() {
    return this.http.get(environment.url + 'nps/gettablecount/',{ headers: { 'x-access-token': this.CommonService.token } });
  }
  getAllNpsCloseList(userId) {
    return this.http.get(environment.url + 'nps/getAllNpsCloseList/'+userId,{ headers: { 'x-access-token': this.CommonService.token } });
  }
  
  getAllSecondNpsCloseList(userId) {
    return this.http.get(environment.url + 'nps/getAllSecondNpsCloseList/'+userId,{ headers: { 'x-access-token': this.CommonService.token } });
  }
  


  // deleteInspectionType(data: {
  //   deleteId: String
  //   deleted_by: String,
  //   deleted_at: String
  // }) {

  //   return this.http.post(environment.url + 'inspectionType/deleteInspectionType', data);
  // }

  // updateInspectionType(id: {
  //   inspection_type_id: String,
  //   inspection_type : String,
  //   updatedBy:String
  // }) {

  //   return this.http.post(environment.url + 'inspectionType/updateInspectionType', id);
  // }
}
